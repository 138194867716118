import React, { useCallback, useEffect, useRef, useState } from 'react';
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import TableBody from '../../components/InvoiceTable/TableBody/TableBody';
import TableActions from '../../components/InvoiceTable/TableActions/TableActions';
import TableHead from '../../components/InvoiceTable/TableHead/TableHead';
import TableFilters from '../../components/InvoiceTable/TableFilters/TableFilters';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import AttachmentModal from '../../components/InvoiceTable/TableBody/AttachmentModal/AttachmentModal';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Header, HeaderActions, HeaderInfos, HeaderTitle } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TableAction, TBody } from '../../styles/Table.styled';
import { SIBody, SIContainer, SITableContainer } from './SuiviInvoices.styled';
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import DetailsModal from '../../components/InvoiceTable/TableBody/DetailsModal/DetailsModal';
import PostdatedInvoicesChart from '../../components/Home/PostdatedInvoicesChart/PostdatedInvoicesChart';
import FutureCalendar from './FutureCalendar/FutureCalendar';

function SuiviInvoice(props) {
    let {
        entityFilter,
        userLevel,
        userType,
        loading,
        invoicesData,
        count,
        currentPage,
        pageSize,
        setInvoicePage,
        setInvoicePageSize,
        getInvoicesPage,
        unfilteredEmptyPage,
        setInvoiceFilters,
        filtersQuery,
        setInvoiceQuickFilter,
        filtering,
        sortQuery,
        reverse,
        setSortQuery,
        setReverse,
        updateShowFilters,
        showFilters,
        selectedRows,
        setSelectedRows,
        getCompressedDoc,
        selectedAllRows,
        setSelectedAllRows,
        amountBTTotal,
        amountATTotal,
        multiEntities,
        profile,
        isFuture,
        isLitigation,
        isNoOrder,
        invoiceSetSearch,
        search,
        statuses,
        invoiceTrackingByDate,
    } = props;
    const [attachmentModalShow, setAttachmentModalShow] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [newMessages, setNewMessages] = useState(0);
    const [messagesToggleOn, setMessagesToggleOn] = useState(false);
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [tableProfile, setTableProfile] = useState(null);
    const { state } = useLocation();
    // get le filtre de statut depuis les KPIs de la vue fournisseur
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const tableRef = useRef(null);

    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const navTitre = "fournisseurInvoices";


    const initialFiltersQuery = {
        visualisation: false,
        isFuture: (isFuture ? 1 : undefined),
        isLitigation: (isLitigation ? 1 : undefined),
        isNoOrder: (isNoOrder ? 1 : undefined),
        entityFilter: entityFilter ? entityFilter : "supplier"
    };
    // init columns
    useEffect(() => {
        Axios.get('/user//navColumns', { params: { profile: profile, navTitre: navTitre } })
            .then(response => {
                let tempColums = response?.data?.columns
                let columnsCopy = [];
                if (entityFilter === "supplier") {
                    // columnsCopy = [
                    //     ...columnsCopy,
                    //     { title: "Pièce jointe", field: 'attachmentFile', width: "100px", show: tempColums.attachmentFile }
                    // ]
                } else if (entityFilter === "client") {
                    columnsCopy = [
                        ...columnsCopy,
                        { title: t("invoiceList:readStatus"), field: 'firstAccessDate', width: "40px", show: tempColums.firstAccessDate }
                    ]
                }

                if (userType === "client") {
                    columnsCopy = [
                        ...columnsCopy,
                        { title: "Code tiers", field: 'supplierCode', width: "120px", show: tempColums.supplierCode },
                        { title: t("invoiceList:issuingSupplier"), field: 'supplierName', width: "200px", show: tempColums.supplierName }
                    ]
                } else if (userType === "supplier") {
                    columnsCopy = [
                        ...columnsCopy,
                        { title: t("entitiesList:entityName", "Entity"), field: 'clientName', width: "200px", show: tempColums.clientName }
                    ]
                    if (multiEntities) {
                        columnsCopy = [
                            ...columnsCopy,
                            { title: t("supplier:supplierCodefournisseur", "Supplier code"), field: 'supplierCode', width: "120px", show: tempColums.supplierCode },
                            { title: t("invoiceList:supplier", "Supplier"), field: 'supplierName', width: "200px", show: tempColums.supplierName }
                        ]
                    }
                } else {
                    columnsCopy = [
                        ...columnsCopy,
                        { title: "Code SU", field: 'clientCode', width: "90px", show: tempColums.clientCode },

                        { title: "Nom entité", field: 'clientName', width: "140px", show: tempColums.clientName },
                        { title: "Code tiers", field: 'supplierCode', width: "120px", show: tempColums.supplierCode },
                        { title: t("invoiceList:supplier"), field: 'supplierName', width: "140px", show: tempColums.supplierName },
                        { title: "Type de dépôt", field: 'channel', width: "70px", show: tempColums.channel }
                    ]
                }
                columnsCopy = [
                    ...columnsCopy,
                    { title: t("invoiceList:docType"), field: 'documentType', width: "100px", show: tempColums.documentType },
                    { title: t("invoiceList:number"), field: 'number', width: "100px", show: tempColums.number },
                    { title: t("invoiceList:issuingDate"), field: 'issuingDate', width: "140px", show: tempColums.issuingDate }
                ];
                if (["REJECT_MANAGER","ACCOUNTING_TLV"].includes(profile) && isFuture)
                    columnsCopy = [
                        ...columnsCopy,
                        { title: "Valider / passer en rejet", field: 'futureValidated', width: "200px", show: true }
                    ]
                columnsCopy = [
                    ...columnsCopy,
                    { title: t("invoiceList:status"), field: 'status', width: "100px", show: tempColums.status },
                    { title: t("invoiceList:statusDate", "Status change date"), field: 'statusDate', width: "50px", show: tempColums.statusDate },
                    { title: t("invoiceList:comments", "Comments"), field: 'unreadMessages', width: "100px", show: tempColums.unreadMessages },
                    { title: t("invoiceList:region", "Region"), field: 'region', width: "50px", show: tempColums.region },
                    { title: t("invoiceList:inclusiveAmount"), field: 'inclusiveTaxAmount', width: "100px", show: tempColums.inclusiveTaxAmount },
                    { title: t("invoiceList:exclusiveAmount"), field: 'exclusiveTaxAmount', width: "100px", show: tempColums.exclusiveTaxAmount },
                    { title: t("invoiceList:currency"), field: 'currency', width: "70px", show: tempColums.currency }
                ]
                if (userType === "owner")
                    columnsCopy = [
                        ...columnsCopy,
                        { title: "Date d'acquisition", field: 'creationDate', width: "100px", show: tempColums.creationDate },
                        { title: t("invoiceList:documentCode", "Code document"), field: 'documentCode', width: "100px", show: tempColums.documentCode },
                        { title: t("invoiceList:internalNumber", "Numéro de facture interne"), field: 'internalNumber', width: "70px", show: tempColums.internalNumber }
                    ]
                setColumns(columnsCopy)
                columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);
            })
    }, [loadColumns, location.pathname, i18n.language])

    const getNewMessages = () => {
        Axios.get('/invoice//checkNewMessages', {
            params: {
                entityFilter: entityFilter
            }
        }).then(response => {
            setNewMessages(response.data.length);
        }).catch(err => {
        })
    }

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            let suppliersState = null;
            if (state?.supplier) {
                let res = state?.supplier?.map(function (item) {
                    return item.code;
                })
                suppliersState = res.join();
            }
            const groupFilterEntities = state?.groups ? state.groups.map(g => g.entities?.map(e => e.code)).flat(1) : [];
            if (state?.entityAW?.code)
                groupFilterEntities.push(state?.entityAW?.code);
            const currency = state?.currency;
            let newFilters = null;
            let dueDate = state?.dueDate;
            let issuingDate = state?.issuingDate;
            let creationDate = state?.creationDate;
            let status = filtersQuery?.[location.pathname]?.status || null;
            let totalLimit = (filtersQuery?.[location.pathname]?.totalLimit === undefined) ? true : filtersQuery[location.pathname].totalLimit
            if (state) {
                if (state.statusFilter) {
                    switch (state.statusFilter) {
                        case "Doublon":
                            status = "err51";
                            break;
                        case "Clôturé":
                            status = "permanentReject";
                            break;
                        case "Clôturé err04x":
                            status = "permanentRejectErr04x";
                            break;
                        default:
                            status = statuses?.find(s => s.label === state.statusFilter)?.id;
                    }
                } else if (state.statusCodeFilter)
                    status = statuses?.find(s => s.id === state.statusCodeFilter)?.id;
                else if (state.statusCodeListFilter) {
                    let statusList = []
                    state.statusCodeListFilter.forEach(c => {
                        let statusTmp = statuses?.find(s => s.id === c)
                        if (statusTmp)
                            statusList.push(statusTmp.id)
                    })
                    status = statusList.join()
                }
            }
            if (filtersQuery?.hasOwnProperty(location.pathname)) {
                if (state) {
                    newFilters = {
                        ...filtersQuery,
                        // [location.pathname]: {
                        //     quickSearch: null,
                        //     ...initialFiltersQuery,
                        //     status: status,
                        //     dueDate: dueDate,
                        //     issuingDate: issuingDate,
                        //     creationDate: creationDate,
                        //     supplierCode: suppliersState,
                        //     clientCode: groupFilterEntities.join(),
                        //     currency: currency
                        // }
                        [location.pathname]: {
                            ...filtersQuery?.[location.pathname],
                            ...initialFiltersQuery,
                            totalLimit: totalLimit,
                            status: status,
                            dueDate: dueDate,
                            issuingDate: issuingDate,
                            creationDate: creationDate
                        }
                    }
                } else {
                    newFilters = {
                        ...filtersQuery,
                        [location.pathname]: {
                            quickSearch: null,
                            ...initialFiltersQuery,
                            totalLimit: totalLimit,
                            status: status,
                            dueDate: dueDate,
                            issuingDate: issuingDate,
                            creationDate: creationDate,
                            supplierCode: suppliersState,
                            clientCode: groupFilterEntities.join(),
                            currency: currency
                        }
                    }
                }
            } else {
                newFilters = {
                    ...filtersQuery,
                    [location.pathname]: {
                        quickSearch: search?.hasOwnProperty(location.pathname) ? search[location.pathname] : null,
                        ...initialFiltersQuery,
                        totalLimit: totalLimit,
                        status: status,
                        dueDate: dueDate,
                        issuingDate: issuingDate,
                        creationDate: creationDate,
                        supplierCode: suppliersState,
                        clientCode: groupFilterEntities.join(),
                        currency: currency
                    }
                }
            }

            // updateShowFilters(false)
            if (!showFilters?.hasOwnProperty(location.pathname)){
                let showFilter = {
                    ...showFilters,
                    [location.pathname]: true
                }
                updateShowFilters(showFilter)
            }  
            setInvoiceFilters(newFilters);
            getInvoicesPage(currentPage, newFilters[location.pathname], sortQuery, reverse, pageSize);
            //getNewMessages();
        }
    }, [getInvoicesPage, entityFilter, pageSize, isFuture, isLitigation, isNoOrder, state, statuses])

    useEffect(() => {
        if (tableProfile?.profileValue) {
            const parsedProfile = JSON.parse(tableProfile.profileValue);
            const columnsTemp = [...columns];
            columnsTemp.forEach(c => {
                c.show = parsedProfile?.columns[c.field];
            })
            setColumns(columnsTemp);
            columnsTemp.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);

            const filtersTemp = Object.assign({ ...initialFiltersQuery }, parsedProfile.filters);
            filtersChangeHandler(filtersTemp);
            handleApplyFiltering({
                ...filtersTemp,
                quickCreationDate: null,
                quickSearch: null
            })
            setInvoicePage(1);
        } else if (tableProfile)
            handleReset()
    }, [tableProfile])

    const isEmptyFilters = () => {
        if (!filtersQuery?.hasOwnProperty(location.pathname))
            return true;
        let filtersList = columns.map(column => column.field)
        filtersList.push("documentType");
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery?.[location.pathname][filter])
                empty = false;
        })
        return Object.keys(filtersQuery?.[location.pathname]).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (showFilters?.hasOwnProperty(location.pathname) && !showFilters[location.pathname] && !isEmpty) {
            handleReset()
        }
    }, [showFilters]);

    const handleShowFilters = () => {
        let showFilter = {
            ...showFilters,
            [location.pathname]: !showFilters[location.pathname]
        }
        updateShowFilters(showFilter)
    }
 
    const handleTotalLimit = () => {  
        let newFilters = {...filtersQuery} 
        newFilters[location.pathname].totalLimit = !newFilters[location.pathname].totalLimit
        filtersChangeHandler( newFilters[location.pathname]); 
        handleApplyFiltering( newFilters[location.pathname])    
    } 

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setInvoicePage(newPage);
        getInvoicesPage(newPage, filtersQuery?.[location.pathname], sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort, newReverse) => {
        getInvoicesPage(currentPage, filtersQuery?.[location.pathname], sort, newReverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getInvoicesPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const handleRefresh = useCallback(() => {
        getInvoicesPage(currentPage, filtersQuery?.[location.pathname], sortQuery, reverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const messagesToggleClickHandler = () => {
        let newValue = !messagesToggleOn;
        setMessagesToggleOn(newValue);
        let filtersTemp = {
            ...initialFiltersQuery,
            unreadMessages: (newValue ? 'on' : 'off')
        }
        // setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const handleReset = useCallback(() => {
        let newFilters = {
            ...initialFiltersQuery
        }
        let filterLocation={
            ...filtersQuery,
            [location.pathname]:newFilters,
        } 
        if (state?.invoiceTrackingByDate){
            if (state?.invoiceTrackingByDate =="issuingDate"){
                filterLocation[location.pathname].issuingDate = state.issuingDate
            }
            else if (state?.invoiceTrackingByDate =="creationDate"){
                filterLocation[location.pathname].creationDate = state.creationDate
            }
        }  
        setInvoiceQuickFilter(null)
        setSortQuery("clientName")
        setReverse({ clientName: false })
        setInvoiceFilters(filterLocation);
        setInvoicePage(1);
        getInvoicesPage(1, newFilters, "clientName", { clientName: false }, pageSize);
    }, [initialFiltersQuery])

    const attachmentHandler = (e, invoice) => {
        e.preventDefault();
        setSelectedInvoice(invoice);
        setAttachmentModalShow(true);
    }

    const filtersChangeHandler = (newFilters) => {
        const filtersTemp = {
            ...newFilters,
            quickCreationDate: null,
            quickSearch: null
        }
        let filterLocation = {
            ...filtersQuery,
            [location.pathname]: filtersTemp,
        }
        setInvoiceQuickFilter(null)
        setInvoiceFilters(filterLocation)
    }

    const quickFilterChangeHandler = (value) => {
        switch (value) {
            case 'thisYear':
                thisYearClickHandler()
                break;
            case 'thisMonth':
                thisMonthClickHandler()
                break;
            case 'lastMonth':
                lastMonthClickHandler()
                break;
            default:
                resetQuickFilter()
                break;
        }
    }

    const resetQuickFilter = () => {
        setInvoiceQuickFilter(null)
        // setInvoiceFilters(initialFiltersQuery)
        handleApplyFiltering(initialFiltersQuery)
    }

    const thisMonthClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setInvoiceQuickFilter('thisMonth')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const lastMonthClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setInvoiceQuickFilter('lastMonth')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const thisYearClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), 0, 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear() + 1, 0, 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setInvoiceQuickFilter('thisYear')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const quickDateIntervalChangeHandler = (date) => {
        let filtersTemp = {
            ...initialFiltersQuery,
            quickCreationDate: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        setInvoiceQuickFilter('quickDateInterval')
        // setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp);
    }

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp2 = {
            ...search,
            [location.pathname]: quickSearchValue,
        }

        let filtersTemp = {
            ...initialFiltersQuery,
            quickSearch: filtersTemp2[location.pathname]
        }
        let filterLocation = {
            ...filtersQuery,
            [location.pathname]: filtersTemp,
        }
        invoiceSetSearch(filtersTemp2)


        setInvoiceQuickFilter('quickSearch')
        setInvoiceFilters(filterLocation)
        if (quickSearchValue?.length >= 3)
            handleApplyFiltering(filterLocation[location.pathname]);
        else if (quickSearchValue?.length < 3 && search?.[location.pathname]?.length >= 3) {
            filterLocation[location.pathname].quickSearch = "";
            handleApplyFiltering(filterLocation[location.pathname]);
        }
    }

    const getStatusLabel = row => {
        if (row?.permanentRejectErr04x && +row?.permanentRejectErr04x && row?.status == "14")
            return "Clôturé err04x";
        else if (row?.permanentReject && +row?.permanentReject && row?.status == "14")
            return "Clôturé";
        else if (row?.rejectTypes?.includes("err51") && row?.status == "14")
            return "Doublon";
        else if (row?.rejectTypes?.includes("err52") && row?.status == "14")
            return "Faux doublon";
        else if (!+row?.permanentReject && row?.status == "14" && userType === "supplier")
            return "Reçue par Transdev";
        else
            return row?.statusLabel;
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : unfilteredEmptyPage ? (
        <NoContent>
            <span>{t("invoiceList:unfilteredEmptyPage")}</span>
        </NoContent>
    ) : (
        <NoContent>
            <span>{t("invoiceList:noInvoices")}</span>
        </NoContent>
    );

    if (!loading && invoicesData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody
                columns={columns}
                data={invoicesData}
                formatDate={formatDate}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                userLevel={userLevel}
                userType={userType}
                attachmentHandler={attachmentHandler}
                entityFilter={entityFilter}
                multiEntities={multiEntities}
                userProfile={profile}
                isFuture={isFuture}
                refreshTable={handleRefresh}
                pathname={location.pathname}
                setShowDetailsModal={setShowDetailsModal}
                getStatusLabel={getStatusLabel}
            />
        )
    }


    return (
        <SIContainer>
            <TitleHelmet title={"Transdev | " + t("menu:invoiceTracking", 'Invoice tracking')} />
            {isFuture && <FutureCalendar tableRef={tableRef} />}
            <Header ref={tableRef}>
                {/* <HeaderInfos>
                    <HeaderTitle>
                        {
                            newMessages > 0 ?
                                <div
                                    className={`messagesToggle ${messagesToggleOn ? ' messagesToggleOn' : ''}`}
                                    onClick={() => messagesToggleClickHandler()}
                                    style={{
                                        display: 'flex',
                                        marginLeft: '0.5rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <TextsmsIcon fontSize="medium" /><br />
                                    <span>{newMessages}</span>
                                </div>
                                : null
                        }
                    </HeaderTitle>
                </HeaderInfos> */}
                <HeaderActions>
                    <SearchInputContainer>

                        <SearchInput
                            type="text"
                            placeholder={t("global:search", "Search") + "..."}
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.hasOwnProperty(location.pathname) && filtersQuery?.[location.pathname]?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        {filtersQuery?.hasOwnProperty(location.pathname) && filtersQuery?.[location.pathname]?.quickSearch !== null ? <CloseIcon onClick={() => quickSearchChange(null)} /> :
                            <SearchIcon />}

                    </SearchInputContainer>
                    {/* <SelectPeriod
                        value={getQuickFilterLabel()}
                        onChange={quickFilterChangeHandler}
                        options={dateFilterOptions}
                    /> */}
                    <TableActions
                        filterState={showFilters?.hasOwnProperty(location.pathname) && showFilters[location.pathname] == true ? true : false}
                        showFilters={handleShowFilters}
                        filtering={filtering}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        filters={filtersQuery?.[location.pathname]}
                        resetData={handleReset}
                        formatDate={formatDate}
                        getCompressedDoc={getCompressedDoc}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows}
                        userLevel={userLevel}
                        userType={userType}
                        entityFilter={entityFilter}
                        getStatusLabel={getStatusLabel}
                        tableCount={count}
                        handleTotalLimit={handleTotalLimit}                                                                       
                        totalLimitState={filtersQuery?.hasOwnProperty(location.pathname) && filtersQuery[location.pathname]?.totalLimit == true ? true : false} 
                    />

                </HeaderActions>
            </Header>
            <SIBody>
                <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                    currentPage={currentPage}
                    pageChange={handleChangePage}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setInvoicePageSize}
                    filters={filtersQuery?.[location.pathname]}
                    tableProfile={tableProfile}
                    setTableProfile={setTableProfile}
                    tableType="INVOICE"
                />

                <SITableContainer>

                    <Table>
                        <TableHead
                            columns={columns}
                            sortQuery={sortQuery}
                            reverse={reverse}
                            setSortQuery={setSortQuery}
                            setReverse={setReverse}
                            getData={handleSort}
                            selectedRows={selectedRows}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={setSelectedAllRows}
                            userProfile={profile}
                            filters={filtersQuery}
                        />
                        <TBody height={density}>
                            {showFilters[location.pathname] && <TableFilters
                                columns={columns}
                                show={showFilters[location.pathname]}
                                formatDate={formatDate}
                                filters={filtersQuery?.[location.pathname]}
                                filtersChange={(filters) => filtersChangeHandler({ ...filters, quickCreationDate: null, quickSearch: null })}
                                applyFilters={handleApplyFiltering}
                                amountBTTotal={amountBTTotal}
                                amountATTotal={amountATTotal}
                                statuses={statuses}
                                userLevel={userLevel}
                                userType={userType}
                                entityFilter={entityFilter}
                                multiEntities={multiEntities}
                                userProfile={profile}
                                isFuture={isFuture}
                                isLitigation={isLitigation}
                            />}
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    {/* <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setInvoicePageSize}
                    /> */}
                    <AttachmentModal show={attachmentModalShow} modalClosed={() => setAttachmentModalShow(false)} invoice={selectedInvoice}></AttachmentModal>
                    <DetailsModal
                        show={showDetailsModal}
                        modalClosed={() => setShowDetailsModal(false)}
                        refreshTable={handleRefresh}
                        statuses={statuses}
                    />
                </SITableContainer>
            </SIBody>
        </SIContainer>
    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    loading: state.invoice.loading,
    invoicesData: state.invoice.data,
    count: state.invoice.count,
    currentPage: state.invoice.currentPage,
    pageSize: state.invoice.pageSize,
    filtersQuery: state.invoice.filtersQuery,
    filtering: state.invoice.filtering,
    sortQuery: state.invoice.sortQuery,
    reverse: state.invoice.reverse,
    showFilters: state.invoice.showFilters,
    selectedRows: state.invoice.selectedRows,
    selectedAllRows: state.invoice.selectedAllRows,
    userLevel: state.auth.userLevel,
    userType: state.auth.userType,
    amountBTTotal: state.invoice.amountBTTotal,
    amountATTotal: state.invoice.amountATTotal,
    profile: state.auth.profile,
    multiEntities: +state.auth.multiEntities,
    search: state.invoice.search,
    unfilteredEmptyPage: state.invoice.unfilteredEmptyPage,
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getInvoicesPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.invoiceGetPage(page, filters, sort, reverse, pageSize)),
    setInvoicePage: (page) => dispatch(actions.invoiceSetPage(page)),
    setInvoicePageSize: (pageSize) => dispatch(actions.invoiceSetPageSize(pageSize)),
    setInvoiceFilters: (filters) => dispatch(actions.invoiceSetFiltersQuery(filters)),
    setInvoiceQuickFilter: (quickFilterField) => dispatch(actions.invoiceSetQuickFilter(quickFilterField)),
    setSortQuery: (sortQ) => dispatch(actions.invoiceSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.invoiceSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.invoiceUpdateShowFilters(show)),
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid)),
    setSelectedRows: (rowId, selceted) => dispatch(actions.invoiceSelectRow(rowId, selceted)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.invoiceSelectAllRows(selectedAll)),
    getCompressedDoc: (selection, filters) => dispatch(actions.invoiceGetCompressedDocument(selection, filters, false)),
    invoiceSetSearch: (search) => dispatch(actions.invoiceSetSearch(search)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SuiviInvoice)
