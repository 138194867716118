import React, { Fragment, useState } from 'react'
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { CTooltip } from '../../UI/CTooltip/CTooltip'
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Axios from '../../../axios-proas'
import { useNavigate } from 'react-router-dom';
import { getNotyfObject, getChannelWithKey,showColumn } from '../../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { TD, TR } from '../../../styles/Table.styled';
import CodeIcon from '@mui/icons-material/Code';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CustomConfirmModal from '../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
import { MenuItem, Select } from '@mui/material';
import { InvoiceNumber } from '../../../containers/SuiviInvoices/SuiviInvoices.styled';

function TableBody(props) {
    const {data, formatDate, selectedRows, setSelectedRows, userType, getStatusLabel, entityFilter,columns, multiEntities, userProfile, isFuture, refreshTable, getInvoiceCounters, getInvoiceData, pathname, setInvoiceUnread} = props;

    const {t} = useTranslation();
    const navigate = useNavigate();
    let notyf = getNotyfObject();
    const [showErr05Modal, setShowErr05Modal] = useState(false);

    //console.log("Entity Filter: " + entityFilter);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 2 }).format(number).replaceAll('.',' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        let seconds = newDate.getSeconds() < 10 ? "0" + newDate.getSeconds() : newDate.getSeconds()
        return hour + ":" + minutes + ":" + seconds
    }

    const clickedHandler = (e, uid) => {
        e.stopPropagation()
        props.getDocumentFile(uid)
    }
    const clickedHandlerEdi = (e, uid) => {
        e.stopPropagation()
        props.getinvoiceGetDocumentEdi(uid)
    }

    
    const clickedHandlerXml = (e, uid) => {
        e.stopPropagation()
        props.getinvoiceGetDocumentXml(uid)
    }

    const clickAttachmentHandler = (e, uid) => {
        e.stopPropagation()
        Axios.get('/invoice/'+uid+'/attachmentFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if(response.status === 200){
                const blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                let filename = "";
                const disposition = response.request.getResponseHeader('Content-Disposition')
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
                }
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement("a"); 
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            } else
                notyf.error("Pièce jointe non trouvée");
        }).catch(err => {
            notyf.error(t("global:errorOccurred", "An error has occurred"))
        })
    }
    
    const setToErr05Handler = (uid) => {
        return new Promise((resolve, reject) => {
            Axios.put("invoice/"+uid+"/err05").then(response => {
                if (response.data.success) {
                    refreshTable();
                    getInvoiceCounters();
                    notyf.success("Succès du passage en err05");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors du passage en err05");
                    reject(false);
                }
            }).catch(() => {
                notyf.error("Échec du passage en err05");
                reject(false);
            })
        })
    }

    const handleActionChange = (event, row) => {
        const value = event?.target?.value;
        switch(value) {
            case "validated":
            case "none":
                const validated = value === "validated" ? 1 : 0;
                Axios.put(`invoice/${row.uid}/validateFuture`, validated).then(() => {
                    notyf.success("Succès de " + (validated ? "la validation" : "l'invalidation") + " de la facture postdatée");
                    refreshTable();
                }).catch(() => {
                    notyf.error("Échec de " + (validated ? "la validation" : "l'invalidation") + " de la facture postdatée");
                })
                break;
            case "err05":
                setShowErr05Modal(row.uid);
                break;
            default:;
        }
    }
    
    return (
        <>           
            {

                data?.map((row,index) => (
                    <TR key={row?.id} onClick={() => navigate("/invoiceDetail/"+row.uid,{state:{entityFilter}})}>
                        <TD>
                            <input type="checkbox" name={row?.uid} onClick={e => e.stopPropagation()} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row?.uid)}  className="form-check-input"  id="exampleCheck1" />
                        </TD>
                        {entityFilter === "client" ?
                         
                        <TD style={{display: !showColumn(columns,"firstAccessDate")&& 'none'}}>
                            {+row?.firstAccessDate > 0 ? 
                                <CTooltip title={formatDate(+row?.firstAccessDate)+" "+ getTimeDate(+row?.firstAccessDate) || ""} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>
                                    <DraftsIcon />
                                </CTooltip> : 
                                <MarkunreadIcon className="tableActions__icon" style={{color: "#ffa618", fontSize: "20px"}} />} 
                        </TD> :
                        (entityFilter === "supplier" && 
                            <TD style={{display: !showColumn(columns,"attachmentFile") && 'none'}}>
                                {row?.attachmentFile && 
                                    <a 
                                        download={row?.attachmentFile} 
                                        href="#" 
                                        onClick={(e) => clickAttachmentHandler(e, row?.uid)} 
                                        style={{color: "#ea5e0b"}}
                                    >
                                        {row?.attachmentFile}
                                    </a>}
                            </TD>
                        )}
                        
                        {userType==='client' ? (
                            <>
                                <CTooltip title={row?.supplierCode || ""}><TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>{row?.supplierCode}</TD></CTooltip>
                                <CTooltip title={row?.supplierName || ""}><TD style={{display: !showColumn(columns,"supplierName") && 'none'}}>{row?.supplierName}</TD></CTooltip>
                            </>):null}
                        {userType==='supplier' ? (
                            <>
                                <CTooltip title={row?.clientCode || ""}><TD style={{display: !showColumn(columns,"clientCode") && 'none'}}>{row?.clientCode}</TD></CTooltip>
                              
                                <CTooltip title={row?.clientName || ""}><TD style={{display: !showColumn(columns,"clientName") && 'none'}}>{row?.clientName}</TD></CTooltip>
                                {multiEntities ? <>
                                    <CTooltip title={row?.supplierCode || ""}><TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>{row?.supplierCode}</TD></CTooltip>
                                    <CTooltip title={row?.supplierName || ""}><TD style={{display: !showColumn(columns,"supplierName") && 'none'}}>{row?.supplierName}</TD></CTooltip>
                                </> : null}
                            </>):null}
                        {userType==='owner' ? (
                            <Fragment>
                                <CTooltip title={row?.clientCode || ""}><TD style={{display: !showColumn(columns,"clientCode") && 'none'}}>{row?.clientCode}</TD></CTooltip>
                              
                                <CTooltip title={row?.clientName || ""}><TD style={{display: !showColumn(columns,"clientName") && 'none'}}>{row?.clientName}</TD></CTooltip>
                                <CTooltip title={row?.supplierCode || ""}><TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>{row?.supplierCode}</TD></CTooltip>
                                <CTooltip title={row?.supplierName || ""}><TD style={{display: !showColumn(columns,"supplierName") && 'none'}}>{row?.supplierName}</TD></CTooltip>
                                <CTooltip title={getChannelWithKey(row.channel) || ""}>
                                    <TD style={{display: !showColumn(columns,"channel")&& 'none'}}>{getChannelWithKey(row.channel)}</TD>
                                </CTooltip>
                            </Fragment>
                            ):null}
                        <CTooltip title={t("invoiceList:" + (row?.documentType === "INV" ? "invoice" : (row?.documentType === "CRN" ? "creditNote" : "unknown"))) || ""}><TD style={{display: !showColumn(columns,"documentType") && 'none'}}>
                            {t("invoiceList:" + (row?.documentType === "INV" ? "invoice" : (row?.documentType === "CRN" ? "creditNote" : "unknown")))}
                        </TD></CTooltip>
                        <TD style={{display: !showColumn(columns,"number") && 'none', cursor: "default"}} onClick={e => e.stopPropagation()}>
                            <InvoiceNumber>
                                <CTooltip title={row?.number || ""}><span /*style={{width: userLevel === "admin" && userProfile === "REJECT_MANAGER" && pathname === "/fournisseurInvoices" ? "80%" : ""}}*/>{row?.number}</span></CTooltip>
                                {/*userLevel === "admin" && userProfile === "REJECT_MANAGER" && pathname === "/fournisseurInvoices" &&
                                    <CTooltip title="Cliquez pour afficher les détails de la facture"><FeedOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => {getInvoiceData(row.uid);setShowDetailsModal(row);}} /></CTooltip>
                                */}
                                {/* <CTooltip title="Copier"><FileCopyIcon /></CTooltip> */}
                            </InvoiceNumber>
                        </TD>
                        <CTooltip title={formatDate(+row?.issuingDate) || ""}><TD style={{display: !showColumn(columns,"issuingDate")&& 'none'}}>{formatDate(+row?.issuingDate)}</TD></CTooltip>
                        {isFuture && ["REJECT_MANAGER","ACCOUNTING_TLV"].includes(userProfile) && <TD>
                            <Select value={+row?.futureValidated ? "validated" : "none"} style={{height: "32px"}} onClick={e => e.stopPropagation()} onChange={e => handleActionChange(e, row)}>
                                <MenuItem value={"none"}>-- Choisissez une action --</MenuItem>
                                <MenuItem value="validated">Facture validée</MenuItem>
                                <MenuItem value="err05">Passer en err05</MenuItem>
                            </Select>
                        </TD>}
                        <TD style={{display: !showColumn(columns,"status") && 'none'}}>
                            <CTooltip title={t("statuses:" + getStatusLabel(row), getStatusLabel(row)) || ""}>
                                <div>{t("statuses:" + getStatusLabel(row), getStatusLabel(row))}</div>
                            </CTooltip>
                        </TD>
                        <TD style={{display: !showColumn(columns,"statusDate") && 'none'}}>
                            <CTooltip title={formatDate(+row?.statusDate) || ""}><div>{formatDate(+row?.statusDate)}</div></CTooltip>
                        </TD>
                        <TD style={{display: !showColumn(columns ,"unreadMessages") && 'none'}}>{
                            row.unreadMessages ? 
                                <CTooltip title={t("invoiceList:newComments", "New comment(s) on ")+ formatDate(+row?.unreadMessages) + " " + getTimeDate(+row?.unreadMessages)+" - "+t("invoiceList:clickToRead", "Click on the invoice to mark the comment(s) as read")} className="tableActions__icon">
                                    <TextsmsIcon fontSize="small" style={{color: "#56296f", fontSize: "20px"}}/> 
                                </CTooltip>
                            : 
                                <CTooltip title={t("invoiceList:doubleClickToUnread", "Double click on the icon to mark the eventual comments as unread")} className="tableActions__icon">
                                    <ChatBubbleOutlineIcon fontSize="small" style={{color: "#56296f", fontSize: "20px"}} onClick={e => e.stopPropagation()} onDoubleClick={e => {setInvoiceUnread(row.uid)}} /> 
                                </CTooltip>
                        }</TD>
                        <TD style={{display: !showColumn(columns ,"region") && 'none'}}><CTooltip title={row?.region || ""}><div>{row?.region}</div></CTooltip></TD>
                        <CTooltip title={formatNumber(row?.inclusiveTaxAmount) || ""}>
                            {/* <TD style={{display: !showColumn(columns,"inclusiveTaxAmount") && 'none', textAlign: "right"}}> */}
                            <TD style={{display: !showColumn(columns,"inclusiveTaxAmount") && 'none'}}>
                            {formatNumber(row?.inclusiveTaxAmount)}
                        </TD></CTooltip>
                        <CTooltip title={formatNumber(row?.exclusiveTaxAmount) || ""}>
                            {/* <TD style={{display: !showColumn(columns,"exclusiveTaxAmount") && 'none', textAlign: "right"}}> */}
                            <TD style={{display: !showColumn(columns,"exclusiveTaxAmount") && 'none'}}>
                            {formatNumber(row?.exclusiveTaxAmount)}
                        </TD></CTooltip>
                        <CTooltip title={row?.currency || ""}><TD style={{display: !showColumn(columns,"currency")&& 'none'}}>
                            {row?.currency}
                        </TD></CTooltip>
                        {userType === "owner" && <>
                        <CTooltip title={formatDate(+row?.creationDate) || ""}><TD style={{display: !showColumn(columns,"creationDate")&& 'none'}}>{formatDate(+row?.creationDate)}</TD></CTooltip>
                        <CTooltip title={row?.documentCode || ""}><TD style={{display: !showColumn(columns,"documentCode")&& 'none'}}>
                            {row?.documentCode}
                        </TD></CTooltip>
                        <CTooltip title={row?.internalNumber || ""}><TD style={{display: !showColumn(columns,"internalNumber")&& 'none'}}>
                            {row?.internalNumber}
                        </TD></CTooltip></>}
                        {!["ACCOUNTING","ACCOUNTING_TLV"].includes(userProfile) && <TD>
                            <div style={{display:"flex"}}>
                               {
                                    row?.docFile &&
                                       <PictureAsPdfIcon onClick={(e) => clickedHandler(e, row?.uid)} style={{fontSize: "1.2rem", cursor: "pointer"}} /> 
                                    }
                                    {row?.xmlFile && userType === "owner" &&
                                        <CodeIcon             
                                            style={{fontSize: "1.2rem", cursor: "pointer"}}  
                                            onClick={(e) => clickedHandlerXml(e, row?.uid)}
                                        />
                                    }
                                    {row?.ediFile &&
                                        <TextSnippetIcon             
                                            style={{fontSize: "1.2rem", cursor: "pointer"}}  
                                             onClick={(e) => clickedHandlerEdi(e, row?.uid)}
                                        />
                                    }
                            </div>
                        </TD>}
                    </TR>
                ))
                
            }
            {
                showErr05Modal && 
                    <CustomConfirmModal 
                        show={showErr05Modal}
                        handleClose={() => setShowErr05Modal(false)}
                        okCallback={() => setToErr05Handler(showErr05Modal)}
                        text={`Êtes-vous sûr de passer cette facture en rejet err05 \"Mentions légales incorrectes\" ?`}/>
            }
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid)),
    getinvoiceGetDocumentXml: (uid) => dispatch(actions.invoiceGetDocumentXml(uid)),
    getinvoiceGetDocumentEdi: (uid) => dispatch(actions.invoiceGetDocumentEdi(uid)),
    getInvoiceCounters: () => dispatch(actions.invoiceGetCounters()),
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    setInvoiceUnread: (invoiceUid) => dispatch(actions.invoiceSetUnread(invoiceUid))
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
